import cn from 'classnames';
import {useEffect, useState} from 'react';

import Image from '@/components/base/elements/Image/Image';
import Link from '@/components/base/elements/Link/Link';
import ButtonGroup, {
  type ButtonGroupProps,
} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import {type CardProps} from '@/components/base/modules/Card/Card';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {
  cardStyles,
  progressStyles,
  slideStyles,
} from '@/components/base/modules/HorizontalSlideshow/styles';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {twMerge} from '@/stylesheets/twMerge';

interface HorizontalSlideshowProps {
  buttonGroup?: ButtonGroupProps;
  cards?: CardProps[];
  mobileCards?: CardProps[];
  className?: string;
  mode?: 'light' | 'dark';
}

export default function CollabsHorizontalSlideshow({
  buttonGroup,
  cards = [],
  mobileCards = [],
  className,
  mode = 'light',
}: HorizontalSlideshowProps) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveIndex((activeIndex + 1) % cards.length);
    }, 8000);

    return () => clearTimeout(timer);
  }, [activeIndex, cards.length]);

  const isMdBreakpoint = useMediaQuery('(min-width: 900px)') ?? true;

  return (
    <div className={twMerge('grid gap-y-xl', className)}>
      <div className={cn('container sm:flex', mobileCards?.length && 'hidden')}>
        {cards?.map((card, index) => (
          <div
            key={`slide-${index}`}
            className={slideStyles({active: index === activeIndex})}
            style={{transform: `translateX(calc(-100% * ${index}))`}}
          >
            {card?.image?.src && <Image {...card.image} />}
            <div className="md:hidden grid-cols-3">
              <div className="grid col-span-3 gap-y-xl">
                <div className="flex flex-wrap gap-x-4 gap-y-6">
                  {Array(cards?.length)
                    .fill(null)
                    .map((_, progressIndex) => (
                      <div
                        key={`progress-${progressIndex}`}
                        onClick={() => handleClick(progressIndex)}
                        className={twMerge(
                          progressStyles({
                            active: progressIndex === activeIndex,
                            mode,
                          }),
                          'w-[30%] before:bg-black',
                        )}
                      />
                    ))}
                </div>
                <HeadingGroup size="t6" mode={mode} {...card.headingGroup} />
                {card.link && (
                  <Link href="/link-1" mode={mode}>
                    {card.link.children}
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {mobileCards && (
        <div className="container sm:hidden">
          {mobileCards.map((card, index) => (
            <div key={`card-${index}`}>
              {card?.image?.src && <Image {...card.image} />}
              <HeadingGroup
                className="mt-4"
                size="t6"
                mode={mode}
                {...card.headingGroup}
              />
              {card.link && (
                <Link href="/link-1" mode={mode}>
                  {card.link.children}
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="hidden md:grid container grid-cols-3 gap-x-gutter">
        {cards?.map((card, index) => (
          <div key={`card-${index}`} className="grid gap-y-md">
            <div
              className="group/card grid gap-y-xl cursor-pointer"
              onClick={() => handleClick(index)}
            >
              <div
                className={progressStyles({
                  active: index === activeIndex,
                  mode,
                })}
              />
              <div
                className={cardStyles({
                  isActive: index === activeIndex,
                })}
              >
                <HeadingGroup size="t6" mode={mode} {...card.headingGroup} />
                {card.link && (
                  <Link href="/link-1" mode={mode}>
                    {card.link.children}
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {buttonGroup && (
        <div className="container">
          <ButtonGroup center={isMdBreakpoint} mode={mode} {...buttonGroup} />
        </div>
      )}
    </div>
  );
}
