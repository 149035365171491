import cn from 'classnames';

import {AlignmentEnum} from '@/enums';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import {
  creatorsHeroVideoThumbnailSrc,
  creatorsInstagramSrc,
  creatorsFacebookSrc,
  creatorsProductSrc,
  creatorsProductSrcUk,
  creatorsCardsSrc,
  creatorsCardsSrcUk,
  creatorsTiktokSrc,
  creatorsYoutubeSrc,
} from '@/components/pages/collabs/collabs-creators/Hero/assets';

interface HeroVideoProps {
  locale?: string;
}

export default function HeroVideo({locale}: HeroVideoProps) {
  const deco = [
    {
      src: locale === 'en-GB' ? creatorsProductSrcUk : creatorsProductSrc,
      alt: 'Product UI showing a small turquoise vial with a white dropper top labeled, “timeless B5 Hydration Serum” with its price and Collabs affiliate link listed below',
      styles: 'border-2 rounded-md bg-white top-0 left-0 w-[45%] z-[2]',
    },
    {
      src: locale === 'en-GB' ? creatorsCardsSrcUk : creatorsCardsSrc,
      alt: 'A pair of cards showing money earned per order and the percentage discount that your audience gets per order',
      styles: 'bottom-[20%] left-[-2%] w-[45%]',
    },
    {
      src: creatorsInstagramSrc,
      alt: 'Instagram',
      styles: 'w-[12%] h-[10%] bottom-[37%] -right-[14%]',
    },
    {
      src: creatorsFacebookSrc,
      alt: 'Facebook',
      styles: 'w-[11%] h-[8%] bottom-[21%] -right-[22%]',
    },
    {
      src: creatorsTiktokSrc,
      alt: 'TikTok',
      styles: 'w-[14%] h-[11%] bottom-[23%] -right-[7%]',
    },
    {
      src: creatorsYoutubeSrc,
      alt: 'Youtube',
      styles: 'w-[11%] h-[9%] bottom-[10%] -right-[7%] -z-0',
    },
  ];

  return (
    <div className="relative mx-auto mt-12 aspect-[33/42] h-fit w-[82%] md:w-screen md:right-0 right-[8%] max-w-[80vw] md:mt-0 md:max-w-[30vw] xl:max-w-[90%]">
      <div
        className={cn(
          'absolute top-[8%] right-0 z-10 h-[85%] w-[64%]',
          'border-4 border-solid border-black rounded-md bg-black',
          'before:absolute before:z-[2] before:w-full before:h-full before:bg-black before:top-[8px] before:right-[8px] before:block before:rounded-md',
        )}
      >
        <WistiaVideoPlayer
          videoId="owkwulann3"
          image={{srcSet: creatorsHeroVideoThumbnailSrc}}
          lazyLoad={false}
          buttonPosition={AlignmentEnum.Left}
          disableBottomBar
          aspectRatio="phone"
          classNames={{
            wrapper: 'z-10 rounded-md',
          }}
        />
      </div>
      {deco.map(({src, alt, styles}) => {
        return (
          <img
            src={src}
            alt={alt}
            key={alt}
            className={`absolute z-10 ${styles}`}
          />
        );
      })}
    </div>
  );
}
