import cn from 'classnames';

import Button from '@/components/pages/collabs/collabs-creators/Header/components/Button';
import SvgShopifyBlack from '@/pages/shopify.com/($locale)/starter/components/icons/logos/ShopifyBlack';
import {useComponentPosition} from '@/hooks/useComponentPosition';
import useReducedMotion from '@/hooks/useReducedMotion';
import useGlobalNav from '@/hooks/navigation/global';
import useSignup from '@/hooks/useSignup';

interface HeaderProps {
  stickyHeaderScrollTrigger?: string;
  className?: string;
  logoLabel: string;
}

export default function Header({
  stickyHeaderScrollTrigger = 'marquee',
  className,
  logoLabel,
}: HeaderProps) {
  const {signupUrl} = useSignup();
  const {homeUrl, loginUrl, loginText, signupText, signupTextPlain} =
    useGlobalNav();
  const {bottom: scrollingHeaderTopStart, scrollPosition} =
    useComponentPosition(
      `section[data-component-name="${stickyHeaderScrollTrigger}"]`,
    );
  const prefersReducedMotion = useReducedMotion(false);

  return (
    <header
      data-component-name="header"
      className={cn(
        className || 'bg-transparent',
        '[&:not(.sticky)]:absolute [&:not(.sticky)]:top-0',
        '[&:not(.sticky)]:left-0 [&:not(.sticky)]:right-0',
        '[&:not(.sticky)]:z-[49]',
        // this rule is terrible
        '[&:not(.sticky)+main>section:first-child]:pt-[108px]',
        {
          'bg-transparent sticky top-0 z-20 animate__animated animate__slideInDown':
            scrollPosition > scrollingHeaderTopStart && !prefersReducedMotion,
        },
      )}
    >
      <div className="mx-auto flex max-w-screen-xl items-center px-[5%] py-5 sm:flex-wrap sm:justify-center">
        <a
          className="mr-auto h-[42px] transition-all hover:invert-[.2] sm:h-[52px]"
          href={homeUrl}
          aria-label={logoLabel}
        >
          <SvgShopifyBlack
            className="h-full w-auto"
            aria-hidden="true"
            aria-label="Shopify"
          />
        </a>
        <div className="flex items-center gap-x-4 sm:gap-x-6">
          <a
            href={loginUrl}
            data-component-name="signin"
            className="flex-[1_0_auto] text-[0.9375rem] transition-all hover:underline hover:invert-[.2] sm:text-xl xs:inline"
          >
            {loginText}
          </a>
          <Button
            size="small"
            type="link"
            href={signupUrl}
            shadow={false}
            componentName="signup"
          >
            <span className="hidden sm:inline">{signupText}</span>
            <span className="inline sm:hidden">{signupTextPlain}</span>
          </Button>
        </div>
      </div>
    </header>
  );
}
