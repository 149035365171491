import type {HTMLAttributes, FC} from 'react';

import ArrowRight from '@/components/brochureV2/icons/ArrowRight';
import {twMerge} from '@/stylesheets/twMerge';

interface CarouselNextButtonProps extends HTMLAttributes<HTMLButtonElement> {
  nextTab: () => void;
}

const CarouselNextButton: FC<CarouselNextButtonProps> = ({
  className,
  nextTab,
  ...rest
}) => {
  return (
    <button
      className={twMerge(
        'transition-all ease-out-cubic duration-300 text-white z-10 rounded-full',
        'flex items-center justify-center absolute bg-[#1238bf] hover:bg-black border-2 border-black',
        'w-[50px] h-[50px] -right-[25px] top-0 hidden sm:block',
        'md:flex md:block md:w-[70px] md:h-[70px] md:-right-[35px]',
        'lg:w-[100px] lg:h-[100px] lg:-right-[50px]',
        className,
      )}
      type="button"
      onClick={() => {
        nextTab();
      }}
      {...rest}
    >
      <ArrowRight className="mx-auto w-5 lg:w-12" />
    </button>
  );
};

export default CarouselNextButton;
