export const creatorsFacebookSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/15ee373d058a5c75a8a5516e14f48e4e.svgz';

export const creatorsHeroVideoThumbnailSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/88a904c75859f5a49c3dd20b2c491821.png?width=292&height=291, https://cdn.shopify.com/b/shopify-brochure2-assets/88a904c75859f5a49c3dd20b2c491821.png 2x';

export const creatorsInstagramSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/8a75654e542930f73d750d89aa37f944.svgz';

export const creatorsProductSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/1b6cd6b58910fc8a220ddef7c9f920c6.png?originalWidth=427&originalHeight=643';

export const creatorsProductSrcUk =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/17ee08255f0809f2d91abc8c8961e58c.png?originalWidth=427&originalHeight=643';

export const creatorsCardsSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/6677de90e1c4128ed212a79c75ba98d8.png?originalWidth=445&originalHeight=269';

export const creatorsCardsSrcUk =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/3ad5c3fa96fd370e4aac2195e7b430d2.png?originalWidth=445&originalHeight=269';

export const creatorsTiktokSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/7b4c187a14701bf17496b7a3af46bd76.svgz';

export const creatorsYoutubeSrc =
  'https://cdn.shopify.com/b/shopify-brochure2-assets/cef776b27fa5c5de46ef3bbae2c3021a.svgz';
