import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';

interface TestimonialTextProps {
  className?: string;
  quoteHtml?: string;
  authorHtml?: string;
  isActive: boolean;
}

const styles = cva('', {
  variants: {
    isActive: {
      true: 'opacity-1 delay-[350ms]',
      false: 'opacity-0',
    },
  },
});

export default function TestimonialText({
  className,
  quoteHtml,
  authorHtml,
  isActive = true,
}: TestimonialTextProps) {
  const qProps = {
    className: twMerge(
      'inline-block',
      'before:content-[open-quote] before:absolute before:-translate-x-full',
      'after:content-[close-quote]',
    ),
  };
  const bqProps = {
    className: twMerge(
      'relative transition-opacity duration-[350ms] ease-linear',
      'font-bold text-t5',
      styles({isActive}),
    ),
  };
  return (
    <div className={className}>
      <blockquote {...bqProps}>
        {quoteHtml && <q {...qProps}>{quoteHtml}</q>}
      </blockquote>
      {authorHtml && (
        <cite>
          <Typography as="p" className="text-body-sm not-italic mt-5">
            {authorHtml}
          </Typography>
        </cite>
      )}
    </div>
  );
}
