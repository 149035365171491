import cn from 'classnames';

import type {BaseButtonComponentProps} from '@/components/shared/BaseButton/BaseButton';
import BaseButton from '@/components/shared/BaseButton/BaseButton';

type ButtonProps = BaseButtonComponentProps & {
  size?: 'small' | 'large';
  shadow?: boolean;
  // should be React.CSSProperties;
  style?: any;
};

export default function Button({
  size = 'large',
  shadow,
  style = {},
  ...buttonProps
}: ButtonProps) {
  const largeClasses = 'py-[18px] px-7 text-t4 leading-normal md:leading-none';
  const shineClasses = `after:absolute
                        after:inset-0
                        after:bg-shine
                        after:[background-size:auto_100%]
                        after:bg-no-repeat
                        after:bg-[position:-3em]
                        after:hover:animate-shine
                        after:hover:[animation-fill-mode:forwards]
                        after:rounded-[36px]
                        motion-reduce:after:hover:animate-none

                        before:inset-0
                        before:absolute
                        before:opacity-0
                        before:bg-collabs-creators-header-button
                        before:-z-10
                        before:transition-opacity
                        before:duration-500
                        before:rounded-[36px]
                        hover:before:opacity-100
                        focus:before:opacity-100

                        hover:scale-100
                        focus:scale-100
                        motion-reduce:hover:scale-95
                        motion-reduce:focus:scale-95`;
  const smallClasses = 'py-2 md:py-4 px-3 md:px-7 sm:px-5';
  const noShadowClasses = 'border-2 md:border-4 border-solid border-shade-90';
  const shadowClasses =
    'border-4 border-[#1f1f1f] shadow-[4px_4px_#1f1f1f] font-shopifysans uppercase';

  const baseClasses = `text-shopifysans
                       font-medium
                       text-center
                       inline-block
                       text-[0.9375rem]
                       sm:text-xl
                       tracking-tight
                       leading-6
                       text-shade-90
                       z-10
                       relative
                       transition-transform
                       duration-300
                       overflow-hidden
                       rounded-[36px]
                       bg-white
                       w-full
                       whitespace-nowrap
                       scale-95`;

  return (
    <BaseButton
      {...buttonProps}
      style={style}
      className={cn(baseClasses, {
        [shineClasses]: true,
        [largeClasses]: size === 'large',
        [smallClasses]: size === 'small',
        [noShadowClasses]: !shadow,
        [shadowClasses]: shadow,
      })}
    />
  );
}
