import cn from 'classnames';
import {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  type RefObject,
} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import {Key} from '@/enums';

interface CarouselTabsHeaderProps extends React.ComponentProps<'div'> {
  items: {
    heading?: string;
  }[];
  currentTabActive: number;
  handleTabSelection: (index: number, call?: string) => void;
  ariaLabelledby?: string;
}

export default function CarouselTabsHeader({
  items,
  currentTabActive,
  handleTabSelection,
  className,
  ariaLabelledby,
}: CarouselTabsHeaderProps) {
  const itemsWrapper = useRef<null | HTMLDivElement>(null);
  const [focusTab, setFocusTab] = useState(0);
  const [tabsRefs, setTabsRefs] = useState<RefObject<HTMLButtonElement>[]>([]);

  const focusHandler = useCallback(
    (idx: number) => () => {
      setFocusTab(idx);
    },
    [setFocusTab],
  );

  const keyUpHandler = useCallback(
    (idx: number) => (event: React.KeyboardEvent) => {
      let nextTabIndex;
      switch (event.key) {
        case Key.Enter:
        case Key.Space:
        case Key.OldSpace:
          handleTabSelection(idx);
          break;

        case Key.ArrowRight:
          nextTabIndex =
            focusTab < items.length - 1 ? focusTab + 1 : items.length - 1;
          tabsRefs[nextTabIndex].current?.focus();
          break;

        case Key.ArrowLeft:
          nextTabIndex = focusTab > 0 ? focusTab - 1 : 0;
          tabsRefs[nextTabIndex].current?.focus();
          break;

        case Key.Home:
          nextTabIndex = 0;
          tabsRefs[nextTabIndex].current?.focus();
          break;

        case Key.End:
          nextTabIndex = items.length - 1;
          tabsRefs[nextTabIndex].current?.focus();
          break;

        default:
          break;
      }
    },
    [handleTabSelection, items, tabsRefs, focusTab],
  );

  // Save tabs refs
  useEffect(() => {
    setTabsRefs(items.map(() => createRef()));
    return () => {};
  }, [items]);

  return (
    <div
      className={twMerge(
        'mx-0 sm:mt-10 mt-5 no-scrollbar sm:whitespace-normal whitespace-nowrap overflow-x-auto p-1',
        className,
      )}
      ref={itemsWrapper}
    >
      <div
        className="inline-block"
        role="tablist"
        aria-labelledby={ariaLabelledby}
      >
        {items?.map(({heading}, index: number) => {
          return (
            <button
              ref={tabsRefs[index]}
              type="button"
              role="tab"
              key={`testimonialsCarousel-tab-${index}`}
              id={`testimonialsCarousel-tab-${index}`}
              className={twMerge(
                cn(
                  'text-t8 p-3 font-bold rounded-[100px] text-shade-50 relative mr-6 inline-block',
                  'overflow-hidden whitespace-nowrap break-words text-left leading-none',
                  {
                    'outline outline-black outline-2 text-black tab-active':
                      currentTabActive === index,
                  },
                ),
              )}
              // Handlers
              onClick={() => handleTabSelection(index)}
              onKeyUp={keyUpHandler(index)}
              onFocus={focusHandler(index)}
              // A11y
              aria-selected={currentTabActive === index}
              aria-controls={`testimonialsCarousel-panel-${index}`}
              tabIndex={currentTabActive === index ? undefined : -1}
            >
              {heading}
            </button>
          );
        })}
      </div>
    </div>
  );
}
