import {useState} from 'react';
import cn from 'classnames';

import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';
import Section from '@/components/base/layouts/Section/Section';
import TestimonialText from '@/components/pages/collabs/collabs-creators/Testimonials/components/TestimonialText';
import CarouselNextButton from '@/components/pages/collabs/collabs-creators/Testimonials/components/CarouselNextButton';
import type {ImageProps} from '@/components/base/elements/Image/Image';
import Image from '@/components/base/elements/Image/Image';
import CarouselTabsHeader from '@/components/pages/collabs/collabs-creators/Testimonials/components/CarouselTabsHeader';
import HtmlTagWrapper from '@/components/brochureV2/primitive/HtmlTagWrapper/HtmlTagWrapper';

interface TestimonialCardProps {
  heading?: string;
  image?: ImageProps;
  merchantQuote?: {
    quoteHtml?: string;
    authorHtml?: string;
  };
}

interface TestimonialProps {
  cards: TestimonialCardProps[];
  headingHtml?: string;
  kicker?: string;
  nextButton?: string;
  nextButtonClassname?: string;
  navClassname?: string;
  contentClassname?: string;
  headingClassName?: string;
  className?: string;
}

const CollabsTestimonials = ({
  cards,
  headingHtml,
  kicker,
  nextButton,
  nextButtonClassname,
  navClassname,
  contentClassname,
  headingClassName,
  className,
}: TestimonialProps) => {
  const [currentTabActive, setTabActive] = useState(0);

  const tabsHeaderItems = cards
    ? cards?.map((card) => ({heading: card.heading}))
    : [];

  const handleTabSelection = (index = 0) => {
    setTabActive(index);
  };

  const nextTab = () => {
    const nextTabIndex =
      currentTabActive + 1 < cards?.length ? currentTabActive + 1 : 0;
    setTabActive(nextTabIndex);
  };

  return (
    <Section
      className={cn('block md:pt-32 sm:pb-48 overflow-x-hidden', className)}
    >
      <div className="container">
        <div className="sm:max-w-[50%]">
          {kicker && (
            <div id="testimonial-kicker">
              <HtmlTagWrapper
                className="text-t7 font-bold uppercase tracking-wide"
                tag="h2"
              >
                {kicker}
              </HtmlTagWrapper>
            </div>
          )}
          {headingHtml && (
            <Typography
              as="h2"
              size="t2"
              className={twMerge(cn('mt-3', headingClassName))}
            >
              {headingHtml}
            </Typography>
          )}
          <CarouselTabsHeader
            items={tabsHeaderItems}
            currentTabActive={currentTabActive}
            handleTabSelection={handleTabSelection}
            className={navClassname}
            ariaLabelledby="testimonial-kicker"
          />
        </div>
        <div className={twMerge(cn('relative', contentClassname))}>
          <CarouselNextButton
            nextTab={nextTab}
            aria-label={nextButton}
            className={twMerge('', nextButtonClassname)}
          />
          {cards?.map((card, index) => {
            const {merchantQuote, image, heading} = card;
            const isPanelActive = currentTabActive === index;
            return (
              <div
                key={`testimonialsCarousel-panel-${index}`}
                id={`testimonialsCarousel-panel-${index}`}
                role="tabpanel"
                className={cn('relative', {
                  hidden: !isPanelActive,
                })}
                aria-hidden={!isPanelActive}
                aria-labelledby={`testimonialsCarousel-tab-${index}`}
              >
                <h3 className="sr-only">{heading}</h3>
                <div className="flex sm:flex-row flex-col-reverse">
                  <div className="sm:w-1/2">
                    <TestimonialText
                      {...merchantQuote}
                      isActive={isPanelActive}
                      className="sm:mt-10 mt-5 sm:pr-10"
                    />
                  </div>
                  <div className="sm:w-1/2 relative sm:mt-0 mt-5">
                    {image && (
                      <div className="sm:absolute static sm:right-0 lg:top-[-300px] sm:top-[-200px]">
                        <Image {...image} className="sm:max-w-[600px] w-full" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default CollabsTestimonials;
