import cn from 'classnames';

import type {Handle} from '@/types';
import PageLayout from '@/components/shared/Page/PageLayout';
import CollabsCreatorsHero from '@/components/pages/collabs/collabs-creators/Hero/CollabsCreatorsHero';
import CollabsHorizontalSlideshow from '@/components/pages/collabs/collabs-creators/HorizontalSlideshow/HorizontalSlideshow';
import CollabsTestimonials from '@/components/pages/collabs/collabs-creators/Testimonials/Testimonials';
import Image from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import LogoGroup from '@/components/base/modules/LogoGroup/LogoGroup';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import SideBySideList from '@/components/base/sections/SideBySideList/SideBySideList';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import {ColorSchemeEnum} from '@/enums';
import CollabsHeader from '@/components/pages/collabs/collabs-creators/Header/CollabsHeader';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {useTranslations} from '@/hooks/useTranslations';

export const handle: Handle = {
  signupUrl:
    'https://api.collabs.shopify.com/creator/auth/login?identity_ux=signup',
  globalNav({t}) {
    return {
      loginUrl: 'https://collabs.shopify.com',
      signupText: t('nav.joinCollabs'),
      signupTextPlain: t('nav.joinCollabs'),
    };
  },
};

export {loader} from '@/utils/server/loader/loader';

export default function CollabsCreators() {
  const {t, images, localeImage, site} = useTranslations();
  const joinCollabsUrl =
    'https://api.collabs.shopify.com/creator/auth/login?identity_ux=signup';

  return (
    <PageLayout
      header={() => (
        <CollabsHeader
          stickyHeaderScrollTrigger="LogoGroup"
          logoLabel={t('global:ariaLabels.shopifyLogo')}
        />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
    >
      <CollabsCreatorsHero
        {...t('hero')}
        ctas={{
          buttons: [
            {
              text: t('hero.cta.buttonPrimary.text'),
              href: joinCollabsUrl,
              intent: 'primary',
              componentName: 'join-shopify-collabs',
            },
          ],
          disclaimerHtml: t('hero.cta.ctaDisclaimer.contentHtml'),
        }}
        className={cn(
          'relative overflow-hidden bg-[#79DFFF] [&>div]:relative [&>div]:z-10',
          'before:z-0 before:absolute before:bottom-[-25%] before:left-[-5%] before:w-[120%] before:h-full',
          'before:bg-collabs-creators-hero-before before:rotate-[-15deg] before:blur-[50px]',
          'after:z-1 after:absolute after:bottom-0 after:left-0 after:w-full after:h-2/6',
          'after:bg-collabs-creators-hero-after',
        )}
        buttonGroupClassName="[&_div>a]:border-black [&_div>a]:bg-currant-70 [&_div>a:hover]:bg-black" // this is icky
        headingClassName="[&_h1]:font-trap"
        backgroundImage={
          <Image
            src={images.hero.backgroundGraphic}
            className="absolute z-1 right-[-20%] bottom-[-15%] h-[115%]"
          />
        }
        locale={site.locale}
      />
      <Section topSpacing="2xl" className="bg-lime-10 pb-0">
        <LogoGroup
          logos={t(`logoSoup.${site.locale}.logos`).map(
            (item: any, idx: number) => ({
              ...item,
              // @ts-ignore
              srcSet: localeImage(images.logoSoup).logos[idx],
            }),
          )}
          className="border-none"
        />
      </Section>
      <Section bottomSpacing="2xl" className="sm:pt-20">
        <SectionHeader
          {...t('sectionHeader')}
          center={true}
          className="[&_h2]:font-trap"
        />
      </Section>
      <Section className="pt-0">
        <CollabsHorizontalSlideshow
          cards={t('horizontalSlideshow.cards').map(
            (item: any, idx: number) => ({
              ...item,
              image: {
                src: localeImage(
                  images.horizontalSlideshow.desktop.cards[idx].image,
                ),
                alt: item.image.alt,
              },
            }),
          )}
          mobileCards={t('horizontalSlideshow.cards').map(
            (item: any, idx: number) => ({
              ...item,
              image: {
                src: localeImage(
                  images.horizontalSlideshow.mobile.cards[idx].image,
                ),
                alt: item.image.alt,
              },
            }),
          )}
        />
      </Section>
      <Section className="py-0">
        <SectionHeader
          {...t('sectionHeader2')}
          center={true}
          className="[&_h2]:font-trap"
        />
      </Section>
      <SideBySideList
        {...t('sideBySideList')}
        image={{
          src: images.sideBySideList.image,
          alt: t('sideBySideList.image.alt'),
        }}
        className="pt-0"
        verticalAlign="center"
      />
      <CollabsTestimonials
        {...t('testimonials')}
        cards={t('testimonials.cards').map((item: any, idx: number) => ({
          ...item,
          image: {
            src: images.testimonials.cards[idx].image,
            alt: item.image.alt,
          },
        }))}
        className="bg-lime-10"
        headingClassName="font-trap"
      />
      <Section className="bg-collabs-creators-conversion bg-no-repeat bg-cover">
        <Conversion
          {...t('conversion')}
          buttons={[
            {
              text: t('conversion.cta.text'),
              href: joinCollabsUrl,
              intent: 'primary',
              componentName: 'join-shopify-collabs',
            },
          ]}
          className="[&_a]:border-black [&_a]:bg-currant-70 [&_a:hover]:bg-black [&_h2]:font-trap"
        />
      </Section>
    </PageLayout>
  );
}
