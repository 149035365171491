import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import {ColorSchemeEnum} from '@/enums';
import useCtasOrSignup from '@/hooks/useCtasOrSignup/useCtasOrSignup';
import {heroBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';
import HeroVideo from '@/components/pages/collabs/collabs-creators/Hero/components/HeroVideo';

interface CollabsCreatorsHeroFloatingImageProps extends HeroProps {
  buttonGroupClassName?: string;
  headingClassName?: string;
  backgroundImage?: JSX.Element;
  locale?: string;
}

export default function CollabsCreatorsHero({
  className,
  colorScheme,
  ctas,
  headingHtml,
  images,
  kicker,
  mode = 'light',
  signup: signupData,
  subheadHtml,
  videos,
  buttonGroupClassName = '',
  headingClassName,
  backgroundImage,
  locale,
  ...props
}: CollabsCreatorsHeroFloatingImageProps) {
  const buttonGroupProps = useCtasOrSignup(signupData || {}, ctas);
  const scheme = colorScheme || ColorSchemeEnum.Lavender;

  return (
    <Section
      className={twMerge(
        'relative pt-hero-top pb-3xl md:pb-0',
        heroBackgroundByTheme[scheme],
        className,
      )}
      {...props}
      componentName="hero"
    >
      <TwoColumn>
        <TwoColumn.Col1 className="flex flex-col pt-3xl sm:col-span-6 my-auto md:py-3xl gap-y-md">
          <HeadingGroup
            kicker={kicker as string}
            headingAs="h1"
            headingHtml={headingHtml as string}
            subheadHtml={subheadHtml as string}
            mode={mode}
            className={headingClassName}
          />
          {buttonGroupProps && (
            <ButtonGroup
              className={buttonGroupClassName}
              mode={mode}
              {...buttonGroupProps}
            />
          )}
        </TwoColumn.Col1>
        <TwoColumn.Col2 className="my-auto sm:col-start-1 sm:col-span-8 md:py-3xl">
          <HeroVideo locale={locale} />
        </TwoColumn.Col2>
      </TwoColumn>
      {backgroundImage}
    </Section>
  );
}
